<template>
  <div>
    <titulo-frequencia />
    <filtro-frequencia
      @atualizar-filtro="atualizarFiltro"
      @filtrar="buscarFrequenciaPorFiltro"
      @limpar-filtro="limparFiltro"
    />
    <tabela-frequencia
      :items="frequencia"
      @atualizar-frequencia="atualizarFrequencia"
    />
    <div class="w-100 d-flex justify-content-between">
      <span></span>
      <b-button variant="info" @click="salvar" :disabled="frequencia?.length === 0">
        {{ $t('GERAL.SALVAR') }}</b-button
      >
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Components:
import TituloFrequencia from '@/views/academico/diario-classe/components/frequencia/TituloFrequencia';
import FiltroFrequencia from './components/frequencia/FiltroFrequencia';
import TabelaFrequencia from './components/frequencia/TabelaFrequencia';

// services
import FrequenciaService from '@/common/services/academico/frequencia.service.js';

export default {
  components: {
    TituloFrequencia,
    FiltroFrequencia,
    TabelaFrequencia,
  },
  data() {
    return {
      filtro: {},
      frequencia: [],
    };
  },
  methods: {
    async buscarFrequenciaPorFiltro() {
      try {
        this.$store.dispatch(START_LOADING);
        const { data } = await FrequenciaService.obterPorFiltro(this.filtro);
        this.frequencia = data;
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    async salvar() {
      let padraoNuloId = '00000000-0000-0000-0000-000000000000';

      const criacao = [];
      const atualizar = [];

      this.frequencia.forEach((item) => {
        item.id === padraoNuloId ? criacao.push(item) : atualizar.push(item);
      });

      try {
        const promises = [];

        if (criacao.length) promises.push(this.criar(criacao));

        if (atualizar.length) promises.push(this.atualizar(atualizar));

        await Promise.all(promises);

        mensagem.sucesso(
          this.$t('GERAL.SUCESSO'),
          this.$t('Frequências atualizadas com sucesso')
        );

        this.buscarFrequenciaPorFiltro();
      } catch (error) {
        mensagem.showErrors(error);
      }
    },

    async criar(frequencia) {
      try {
        this.$store.dispatch(START_LOADING);
        await FrequenciaService.criar(frequencia);
      } catch (error) {
        mensagem.showErrors(error);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    async atualizar(frequencia) {
      try {
        this.$store.dispatch(START_LOADING);
        await FrequenciaService.editar(frequencia);
      } catch (error) {
        mensagem.showErrors(error);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    // HELPERS
    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },

    atualizarFrequencia(frequencia) {
      this.frequencia = frequencia;
    },

    limparFiltro() {
      this.frequencia = [];
    },
  },
};
</script>
