<template>
  <b-table
    :fields="tabela.fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive
    show-empty
    stacked="md"
    striped
    hover
  >
    <template #head(presente)="item">
      <div class="col-xl-12 flex">
        <input-checkbox
          :title="$t('GERAL.MARCAR_TODOS')"
          v-model="marcarTodasFrequencias"
          :aria-label="$t('GERAL.MARCAR_TODOS')"
          aria-describedby="checkbox-label"
          @change="marcarTodos"
        />
        <span>{{ item.label }}</span>
      </div>
    </template>

    <template #cell(presente)="item">
      <template>
        <div>
          <div class="col-xl-12 flex">
            <input-checkbox
              v-model="item.item.presente"
              @change="desabilitarJustificativa(item)"
            />
          </div>
        </div>
      </template>
    </template>

    <template #cell(faltaJustificada)="item">
      <template>
        <div>
          <div class="col-xl-12 flex">
            <input-checkbox
              v-model="item.item.faltaJustificada"
              @change="desabilitarPresenca(item)"
            />
          </div>
        </div>
      </template>
    </template>

    <template #cell(observacao)="item">
      <template>
        <b-input-group class="mt-3">
          <div class="col-xl-12 flex">
            <input-text
              class="mr-2"
              ref="observacao"
              v-model="item.item.observacao"
              :disabled="editarValorObservacao !== item.index ? true : false"
            />
            <b-input-group-append
              class="d-flex justify-content-center align-items-center mb-2"
            >
              <b-button
                variant="primary"
                @click="habilitarObservacao(item.index)"
                >✎
                <span v-if="item.item.faltaJustificada" class="alerta">*</span>
              </b-button>
            </b-input-group-append>
          </div>
        </b-input-group>
      </template>
    </template>
  </b-table>
</template>
<script>
import InputCheckbox from '@/components/inputs/InputCheckbox.vue';
import { InputText } from '@/components/inputs/';

export default {
  components: { InputCheckbox, InputText },
  props: {
    items: { type: Array, default: Array },
  },

  data() {
    return {
      marcarTodasFrequencias: false,
      tabela: {
        fields: [
          {
            key: 'aluno',
            label: 'Aluno',
            sortable: true,
          },
          {
            key: 'presente',
            label: 'Frequência',
            sortable: true,
          },
          {
            key: 'faltaJustificada',
            label: 'Falta justificada',
            sortable: true,
          },
          {
            key: 'observacao',
            label: 'Observações',
          },
        ],
      },
      editarValorObservacao: '',
      faltaJustificadaIndex: '',
      faltasJustificadasCheck: [],
    };
  },
  watch: {
    items: {
      handler() {
        this.$emit('atualizar-frequencia', this.items);
      },
      deep: true,
    },
  },
  methods: {
    desabilitarPresenca(item) {
      if (item.item?.faltaJustificada) {
        this.items[`${item.index}`].presente = false;
        this.faltaJustificadaIndex = item.index;
      } else if (!item.item?.faltaJustificada) {
        this.faltaJustificadaIndex = undefined;
      }
    },
    desabilitarJustificativa(item) {
      if (item.item?.presente) {
        this.items[`${item.index}`].faltaJustificada = false;
      }
    },
    habilitarObservacao(index) {
      this.editarValorObservacao = index;
    },
    marcarTodos() {
      for (const item of this.items) {
        item.presente = this.marcarTodasFrequencias;
      }
    },
  },
};
</script>
<style>
.flex {
  display: flex;
}
.flex-text-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
}
</style>
